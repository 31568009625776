<template>
  <div class="mb-4">
    <!-- <Sidebar /> -->
    <HeaderPage />
    <v-card height="auto" style="padding: 0px;margin: 0px" flat>
      <div class="mt-7 pt-7 ps-3 me-2">
        <p class="ps-3 text_title">{{ $t("message.shopping-terms") }}</p>
        <div class="mt-4">
          <h4 class="text-size">ECモール「Tajimingo」利用規約</h4>
          <span class="text-size">サービスをご利用のみなさまへ</span>
          <p class="paragraph-size">
            株式会社トーカイ薬局(以下「当社」といいます)が運営するECモール「Tajimingo」(以下「本サービス」といいます)の利用規約を以下の通り定めます。
            本サービスの利用に際しては、利用規約の全文をお読みいただいたうえで、同意いただく必要があります。
          </p>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第1条(定義)
          <div class="mt-3 mb-1" style="font-size: 12px">
            利用規約において、次の用語は、次の各号にそれぞれ定める意味で用いるものとします。
            <p class="text-paragraph">
              (1)「利用者」:モールにアクセスし、ショップで商品等の提供を受ける個人または法人但し、商品等の配送先を岐阜県多治見市内の住所を指定できる場合に限ります。
            </p>
            <p class="text-paragraph">
              (2)「出店者」:モールに出店することを当社に申し込み、当社が出店を認めた個人または法人
            </p>
            <p class="text-paragraph">
              (3)「会員」:当社が定める手続に従い、利用規約に同意の上、入会の申し込みを行った者
            </p>
            <p class="text-paragraph">
              (4)「モール」:当社が、インターネット上に運営する、商品等を提供するために必要な機能を有するシステム全体(ハードウェアおよびソフトウェアを含む)
            </p>
            <p class="text-paragraph">
              (5)「ショップ」:モール上で出店者が商品等を利用者に対して提供するためのサイト
            </p>
            <p class="text-paragraph">
              (6)「商品等」:出店者がショップで利用者に提供する物品またはサービス
            </p>
            <p class="text-paragraph">
              (7)「本サービス」:当社がモールを通じて利用者に対し提供する、第3条に定めるサービス
            </p>
            <p class="text-paragraph">
              (8)「会員情報」:会員が当社ならびに出店者に開示した会員の属性に関する情報および会員の取引に関する履歴等の情報
            </p>
          </div>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第2条(契約の成立等)
          <div class="mt-3" style="font-size: 12px">
            <p class="text-paragraph mb-1">
              1　本規約は、モールおよび本サービスの利用に関し、当社および利用者に適用されます。
            </p>
            <p class="text-paragraph mb-1">
              2　利用者は、本利用規約内容を承諾の上、モールおよび本サービスの利用を申込みます。
            </p>
            <p class="text-paragraph mb-1">
              3　モールおよび本サービスの利用契約は、当社が、前項の申込を承諾したときに成立します。
            </p>
          </div>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第3条(本サービス)
          <div class="mt-3 mb-1" style="font-size: 12px">
            1.当社がモールにより提供する本サービスは、次のとおりとします。
            <p class="text-paragraph">
              (1)出店者が提供する商品等についてのオンラインによる情報の提供
            </p>
            <p class="text-paragraph">
              (2)利用者の出店者に対する商品等に関する注文情報の転送
            </p>
          </div>
          <div class="mt-2 mb-1" style="font-size: 12px">
            2.当社は利用者に通知することなく、いつでも本サービスの内容を追加または削除することができるものとし、当該追加または削除により利用者に損害が生じても一切責任を負わないものとします。
          </div>
          <div class="mt-2 mb-1" style="font-size: 12px">
            3.利用者は本サービスを私的にのみ利用するものとし、本サービスを用いて営業を行わないものとします。
          </div>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第4条(サービスの中断・停止等)
          <div class="mt-3 mb-1" style="font-size: 12px">
            1.当社は、本サービスの稼動状態を良好に保つために、次の各号に該当する場合、事前に通知することなく、本サービスの全てあるいは一部の提供を停止または中断をすることがあります。
          </div>
            <p class="text-paragraph">
              (1)システムの点検または保守のために必要な場合
            </p>
            <p class="text-paragraph">
              (2)システムに負荷が集中した場合
            </p>
            <p class="text-paragraph">
              (3)火災、停電、第三者による妨害行為などによりシステムの運用が困難になった場合
            </p>
            <p class="text-paragraph">
              (4)その他、やむをえずシステムの停止または中断が必要と当社が判断した場合
            </p>
          <div class="mt-2 mb-1" style="font-size : 14px">
            2.当社は、本条に基づき当社が行った措置により利用者に生じた損害について一切の責任を負いません。
          </div>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第5条(サービスの変更・廃止)
          <div class="mt-3 mb-1" style="font-size: 12px">
            1.当社は、利用者に事前に通知することにより本サービスの全部または一部を終了することができるものとします。
          </div>
          <div class="mt-2 mb-1" style="font-size: 12px">
            2.当社は、本条に基づき当社が行った措置により利用者に生じた損害について一切の責任を負いません。
          </div>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第6条(禁止事項)
          <div class="mt-3 mb-1" style="font-size: 12px">
            1.利用者は、本サービスの利用において以下の各号に定める行為をしてはならないものとします。
          </div>
          <p class="text-paragraph">
            (1)法令または利用規約、Tajimingo のお買い物上のご注意、その他の規約等に違反すること
          </p>
          <p class="text-paragraph">
            (2)当社、出店者およびその他の第三者の権利、利益、名誉等を損ねること
          </p>
          <p class="text-paragraph">
            (3)公の秩序または公序良俗に反すること
          </p>
          <p class="text-paragraph">
            (4)反社会的勢力に対する利益供与その他の協力をすること
          </p>
          <p class="text-paragraph">
            (5)犯罪による収益の移転行為およびこれを助長、幇助等すること
          </p>
          <p class="text-paragraph">
            (6)第三者へのなりすまし、または意図的に虚偽の情報を送信すること
          </p>
          <p class="text-paragraph">
            (7)購入する意図なく商品の購入を申し込むこと
          </p>
          <p class="text-paragraph">
            (8)正当な理由なく商品を受け取らないこと
          </p>
          <p class="text-paragraph">
            (9)正当な理由なく返品等をすること
          </p>
          <p class="text-paragraph">
            (10)本サービスの運営を妨げること
          </p>
          <p class="text-paragraph">
            (11)当社のサーバその他のコンピュータに不正にアクセスすること
          </p>
          <p class="text-paragraph">
            (12)コンピューターウィルス等有害なプログラムを、当サービスに関連して使用、または提供すること
          </p>
          <p class="text-paragraph">
            (13)パスワードを第三者に貸与・譲渡すること、または第三者と共用すること
          </p>
          <p class="text-paragraph">
            (14)その他当社が不適切と判断すること
          </p>
          <div class="mt-2 mb-1" style="font-size: 12px">
            2.前項各号に定める行為によって利用者に生じた損害については、当社は一切責任を負いません。
          </div>
          <div class="mt-2 mb-1" style="font-size: 12px">
            3.利用者が第1項に違反したことによって、当社に損失ないし損害が生じた場合、利用者は、当社に対し、違反したことにより利用者が得た利益を返還、また当社に生じた損害を、直ちに賠償しなければなりません。
          </div>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第7条(反社会的勢力の排除)
          <div class="mt-3 mb-1" style="font-size: 12px">
            1.利用者は、利用者が、現在、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者(以下これらを「暴力団員等」という。)に該当しないこと、および次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。
          </div>
          <p class="text-paragraph">
            (1)暴力団員等が経営を支配していると認められる関係を有すること
          </p>
          <p class="text-paragraph">
            (2)暴力団員等が経営に実質的に関与していると認められる関係を有すること
          </p>
          <p class="text-paragraph">
            (3)自己、自社もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること
          </p>
          <p class="text-paragraph">
            (4)暴力団員等に対して資金等を提供し、または便宜を供与するなどの関与をしていると認められる関係を有すること
          </p>
          <p class="text-paragraph">
            (5)役員または経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること
          </p>
          <div class="mt-2 mb-1" style="font-size: 12px">
            2.利用者は、利用者自ら、または第三者を利用して次の各号の一にでも該当する行為を行わないことを確約するものとします。
          </div>
          <p class="text-paragraph">
            (1)暴力的な要求行為
          </p>
          <p class="text-paragraph">
            (2)法的な責任を超えた不当な要求行為
          </p>
          <p class="text-paragraph">
            (3)取引に関して、脅迫的な言動をし、または暴力を用いる行為
          </p>
          <p class="text-paragraph">
            (4)風説を流布し、偽計を用いまたは威力を用いて当社の信用を毀損し、または当社の業務を妨害する行為
          </p>
          <p class="text-paragraph">
            (5)その他前各号に準ずる行為
          </p>
          <div class="mt-2 mb-1" style="font-size: 12px">
            3.利用者は、利用者が暴力団員等もしくは第1項各号のいずれかに該当し、もしくは前項各号のいずれかに該当する行為をし、または第1項の規定に基づく表明・確約に関して虚偽の申告をしたことが判明し、利用者との取引を継続することが不適切であると当社が判断する場合には、当社からの通知により本契約を解約されたとしても異議はないものとします。
          </div>
          <div class="mt-2 mb-1" style="font-size: 12px">
            4.前項の規定の適用により、利用者に損害が生じた場合にも、当社になんらの請求をしません。また、当社に損害が生じたときは、利用者がその責任を負うものとします。
          </div>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第8条(通知)
          <div class="mt-3 mb-1" style="font-size: 12px">
            本サービスに関する当社から利用者への通知等は本サービスにかかるウェブサイト上への掲示のほか、その他当社が適当と判断する方法によって行います。
          </div>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第9条(商品の購入)
          <div class="mt-2 mb-1" style="font-size: 12px">
            1.利用者は本サービスを利用して出店者より商品等を購入することができます。ただし、未成年の方は酒類商品等を購入できません。
          </div>
          <div class="mt-2 mb-1" style="font-size: 12px">
            2.利用者は、商品等の購入を希望する場合、出店者が指定する方法に従って商品の購入またはサービスの利用を申込むものとします。利用者は、申込内容につき真実かつ正確なデータを入力し、送信するものとします。
          </div>
          <div class="mt-2 mb-1" style="font-size: 12px">
            3.前項の申込に対して、出店者が承諾する場合、利用者が申請したメールアドレス宛に承諾する旨をメールで送信し、それが利用者に到達した時点をもって、売買契約の成立とさせていただきます。
          </div>
          <div class="mt-2 mb-1" style="font-size: 12px">
            4.前項の規定に拘わらず、本サービス利用に関して不正行為もしくは不適当な行為があった場合、または利用者指定のクレジットカード会社から与信不履行等の旨の連絡があった場合、当社は、売買契約を取消しもしくは解除、履行停止その他適切な措置を取ることができるものとします。
          </div>
          <div class="mt-2 mb-1" style="font-size: 12px">
            5.本サービスによる商品等の配送は岐阜県多治見市内に限ります。
          </div>
          <div class="mt-2 mb-1" style="font-size: 12px">
            6.当社は以下各号のいずれかの場合、当社の判断で当該売買契約の取消、解除、その他適切な措置をとることができるものとします。
          </div>
          <p class="text-paragraph">
            (1)不正行為または不適当な行為があった場合、またはこれを強く疑わせる事情があった場合
          </p>
          <p class="text-paragraph">
            (2)届先不明等により容易にお届けできない場合
          </p>
          <p class="text-paragraph">
            (3)お支払いが困難であると認める事情が判明した場合
          </p>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第10条(決済方法)
          <div class="mt-3 mb-1" style="font-size: 12px">
            1.商品等のお支払金額は、商品購入代金、配送料および消費税の合計となります。
          </div>
          <div class="mt-2 mb-1" style="font-size: 12px">
            2.本サービスによって購入された商品等のお支払いに関しては、利用者本人名義のクレジットカードによるお支払い、出店者指定口座へのお振込みによるお支払い、宅配配送での代金(現金)引換えによるお支払い、または当社が別途認めるお支払方法を利用するものとします。
          </div>
          <div class="mt-2 mb-1" style="font-size: 12px">
            3.クレジットカードでのお支払いの場合は、利用者がカード会社との間で別途契約する条件に従うものとします。なお、利用者と当該カード会社の間で紛争が発生した場合は、当該当事者双方で解決するものとします。
          </div>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第11条(注文のキャンセル)
          <div class="mt-3 mb-1" style="font-size: 12px">
            商品注文確定後の返品・キャンセル等のトラブルに関して当社は責任を負いませんので、利用者は直接出店者とやり取りを行い、解決を行うものとします。
          </div>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第12条(商品等の返品等)
          <div class="mt-3 mb-1" style="font-size: 12px">
            商品等に瑕疵(乱丁、落丁、破損、汚損)や品違い等を見つけた場合は直ちに出店者に通知し た上で、出店者との間で返品方法を協議してください。なお、当社は本サービスの運営者であり返品等と直接受け付けることはありません。
          </div>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第13条(個人情報の第三者提供)
          <div class="mt-3 mb-1" style="font-size: 12px">
            利用者は、本サービスの利用の際に提供した顧客情報をモールの運営、広告または宣伝のために必要な範囲で、出店者に提供されることに同意することとします。
          また、利用者は本サービスの利用の際に提供した個人情報を、商品等の配送業務に必要な範囲で、配送事業者に提供されることに同意することとします。
          </div>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第14条(情報の管理)
          <div class="mt-3 mb-1" style="font-size: 12px">
            当社は、利用者がサービスの利用に関して行ったコメントその他の情報(個人を特定する情報は別途規定するプライパシーポリシーに従います)について、利用者に断りなく当社あるいは出店者において利用できるものとし、次の各号に該当する場合には、利用者に断りなくこれを削除することができるものとします。
          </div>
          <p class="text-paragraph">
            (1)当該情報が当社もしくは第三者の著作権その他の権利を明らかに侵害し、または当社もしくは第三者の名誉もしくは信用を明らかに毀損していると認められた場合
          </p>
          <p class="text-paragraph">
            (2)日本または適用ある外国の法令に明らかに違反していると認められた場合
          </p>
          <p class="text-paragraph">
            (3)官公庁、裁判所等の公的機関から、法律に基づき削除するよう命令(仮処分命令を含む。)を受けた場合
          </p>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第15条(免責)
          <div class="mt-3 mb-1" style="font-size: 12px">
            1.出店者との取引内容・取扱商品・サービス・ページ上の記載内容・個人情報の取扱等は、出店者に直接お問合せください。利用規約における当社の責任は合理的な努力をもって本サービスを運営することに限られるものとし、当社は商品等に関する内容の真偽、正確性、最新性、有用性、信頼性、適法性、第三者の権利を侵害していないことなどについて一切責任を負わず、またいかなる保証もいたしません。
          </div>
          <div class="mt-2 mb-1" style="font-size: 12px">
            2.通信回線やコンピュータ等の障害によるシステムの遅延・変更・中断・中止・データの消失、データへの不正アクセス等により生じた損害、その他利用者に生じた損害について当社は一切責任を負わないものとします。
          </div>
          <div class="mt-2 mb-1" style="font-size: 12px">
            3.利用者が利用規約等に違反したことによって生じた損害については、当社は一切責任を負わないものとします。
          </div>
          <div class="mt-2 mb-1" style="font-size: 12px">
            4.当社は、利用者による本サービスの利用に関連して、利用者に対する責任を負う場合には、当社の故意または重大な過失による場合を除き、利用者に現実に生じた通常かつ直接の範囲の損害に限り、これを賠償します。
          </div>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第16条(会員の登録)
          <div class="mt-3 mb-1" style="font-size: 12px">
            1.利用規約に同意の上、所定の入会申込み手続をされた個人は、登録手続完了後に会員としての資格を有します。ただし、以下の各号のいずれかの事由に該当する場合は、登録および再登録を拒否する場合があり、またその理由については一切開示義務を負いません。
          </div>
          <p class="text-paragraph">
            (1)過去に会員資格が取り消された場合
          </p>
          <p class="text-paragraph">
            (2)サービスを悪用するおそれや、第三者に迷惑をかけたりするおそれがある場合
          </p>
          <p class="text-paragraph">
            (3)当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合
          </p>
          <p class="text-paragraph">
            (4)未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合.
          </p>
          <p class="text-paragraph">
            (5)その他、当社が登録に相応しくないと判断した場合
          </p>
          <div class="mt-2 mb-1" style="font-size: 12px">
            2.会員登録手続は、会員となるご本人が行ってください。代理による登録は一切認められません。
          </div>
          <div class="mt-2 mb-1" style="font-size: 12px">
            3.会員登録手続の際には、入力上の注意をよく読み、所定の入力フォームに必要事項を正確に入力してください。
          </div>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第17条(パスワード管理)
          <div class="mt-3 mb-1" style="font-size: 12px">
            パスワードは、第三者に知られることがないよう定期的に変更する等、会員本人が責任をもって管理しなければなりません。それらが盗用、不正利用その他の事情により会員以外の者が利用した場合であっても、それにより生じた損害について当社は一切責任を負わないものとします。
          </div>
        </div>
        <div class="mt-4" style="font-size: 14px">
          第18条(会員情報の変更)
          <div class="mt-3 mb-1" style="font-size: 12px">
            1.会員は、氏名、住所など当社に届け出た事項に変更があった場合には、直ちに本サービスのマイページより変更手続きをするものとします。
          </div>
          <div class="mt-2 mb-1" style="font-size: 14">
            2.変更登録がなされなかったことにより会員に生じた損害について、当社および出店者は一切責任を負いません。また、変更登録がなされた場合でも、変更登録前にすでに手続がなされた取引は、変更登録前の情報に基づいて行われますのでご注意ください。
          </div>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第19条(退会)
          <div class="mt-3 mb-1" style="font-size: 12px">
            1.会員が退会を希望する場合には、会員本人が退会手続きを行ってください。所定の退会手続の終了後に、退会となります。
          </div>
          <div class="mt-2 mb-1" style="font-size: 12px">
            2.退会にあたり、本サービスの出店者に対して負っている債務がある場合は、会員は、本サービスの出店者に対して負っている債務の一切について当然に期限の利益を失い、直ちに本サービスの出店者に対してすべての債務の支払を行わなければなりません。
          </div>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第20条(会員資格の喪失および賠償義務)
          <div class="mt-3 mb-1" style="font-size: 12px">
            1.当社は、会員が以下の各号のいずれかの事由にあたる場合、会員資格を一時的に停止、または取り消すことができることとします。
          </div>
          <p class="text-paragraph">
            (1)利用規約のいずれかの条項に違反した場合
          </p>
          <p class="text-paragraph">
            (2)会員が、第6条1項各号に定める行為をした場合
          </p>
          <p class="text-paragraph">
            (3)通信販売による代金支払債務の履行を怠った場合
          </p>
          <p class="text-paragraph">
            (4)その他、当社が本サービスの利用、会員資格の保有の継続を適当でないと判断した場合
          </p>
          <div class="mt-2 mb-1" style="font-size: 12px">
            2.前項に該当し、会員資格を一時的に停止、または取り消しとなった際、本サービスの出店者に対して負っている債務がある場合、会員は本サービスの出店者に対して負っている債務の一切について当然に期限の利益を失い、直ちに本サービスの出店者に対してすべての債務の支払を行わなければなりません。
          </div>
          <div class="mt-2 mb-1" style="font-size: 12px">
            3.第1項に該当し、会員資格を一時的に停止、または取り消しとなった場合でも当社は、本条に基づき当社が行った行為により会員に生じた損害について一切の責任を負いません。
          </div>
        </div>

        <div class="mt-4" style="font-size: 14px">
          第21条(準拠法)
          <div class="mt-3 mb-1" style="font-size: 12px">
            1.利用規約の解釈を巡って疑義が生じた場合、当社は合理的な範囲でその解釈を決定できるものとします。
          </div>
          <div class="mt-2 mb-1" style="font-size: 12px">
            2.利用規約に関する全ての紛争については名古屋地方裁判所を第一審の専属的合意管轄裁判所 といたします。
          </div>
        </div>
      </div>
      <!-- btn to top page  -->
      <!-- <v-fab-transition>
        <v-btn
          v-scroll="onScroll"
          v-show="fab"
          class="mx-2"
          fab
          dark
          small
          fixed
          bottom
          right
          style="height: 50px; width: 50px"
          color="#ff0090"
          @click="toTop"
          >
          <v-icon dark size="40">
              mdi-chevron-up
          </v-icon>
        </v-btn>
      </v-fab-transition> -->
    </v-card>
    <div class="mt-7" style="margin-bottom: 8rem">
      <v-card class="d-flex justify-center" flat>
        <v-btn @click.prevent="$router.push('/')" color="#ff0090" depressed class="white--text">
          {{ $t("message.go-shopping") }}
        </v-btn>
      </v-card>
      <CategoryFooter class="mb-0 p-0"  />
    </div>
    <Sidebar />
  </div>
</template>

<script>
import Sidebar from "../../components/developmentv2/Sidebar.vue";
import HeaderPage from '../../components/developmentv2/HeaderPage.vue'
import CategoryFooter from "../../components/CategoryFooter.vue";
export default {
  name: "ShoppingTerms",
  components: {
    Sidebar,
    CategoryFooter,
    HeaderPage
  },
  data() {
    return {
      onlineShop: require("../../assets/onlineshop.jpg"),
      // fab: false
    };
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
  methods: {
    // onScroll (e) {
    //   if (typeof window === 'undefined') return
    //   const top = window.pageYOffset ||   e.target.scrollTop || 0
    //   // const bottom = window.pageYOffset ||   e.target.scrollTop || 0
    //   // (window.innerHeight + window.scrollY) >= document.body.offsetHeight
    //   // console.log(bottom);
    //   this.fab = top > 20
    // },
    // toTop () {
    //   this.$vuetify.goTo(0)
    //   // window.scrollTo({
    //   //   top: document.body.scrollHeight,
    //   //   behavior: "smooth"
    //   // })
    // }
  }
};
</script>

<style>

.text_title {
  border-left: 10px solid #ff0090;
  font-size: 18px;
}
.text-size {
  font-size: 14px;
}
.paragraph-size {
  font-size: 14px;
}
.text-paragraph {
  white-space: pre-line;
  font-size: 12px;
}
</style>
